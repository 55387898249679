import React from 'react'
import PropTypes from 'prop-types'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CCardHeader,
  CProgress,
  CRow,
  CProgressBar,
} from '@coreui/react'

const defaultProps = {
  message: 'Loading...',
}

const Loading = (props) => {
  const { message } = { ...defaultProps, ...props }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardHeader>{message ? message : 'Loading...'}</CCardHeader>
                <CCardBody>
                  <CProgress className="mb-3">
                    <CProgressBar color="info" variant="striped" animated value={100} />
                  </CProgress>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

Loading.propTypes = {
  message: PropTypes.string,
}
export default Loading
